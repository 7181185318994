<template>
  <v-card flat class="pt-0 statistics rounded-xl mt-2">
    <v-row no-gutters v-if="ticketsLoading">
      <v-col v-for="n in 5" :key="n" cols="12">
        <v-skeleton-loader class="mx-auto" type="list-item-avatar"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else-if="widgetCount > 0" class="pa-0 mt-0">
      <v-col cols="12">
        <v-row class="ma-0 pt-0" justify="center">
          <v-card
            v-for="(widget, index) in listOfWidgets" :key="index"
            class="px-1 ma-2"
            flat
          >
            <v-badge bordered color="primary" :content="widget.count && widget.count > 0 ? widget.count : '0'" overlap>
              <v-btn x-small @click="navigateToTickets(widget._id)" class="white--text pt-0" color="primary" depressed>
                {{ $t(widget.name) }}
              </v-btn>
            </v-badge>
          </v-card>
          <!-- <v-spacer></v-spacer> -->
          <v-card
            class="px-1 ma-2"
            flat
          >
          <v-btn-toggle mandatory color="primary" v-model="ticketType" rounded-pop dense>
            <v-btn small>{{ $t('day') }}</v-btn>
            <v-btn small>{{ $t('monthly') }}</v-btn>
            <v-btn small>{{ $t('annual') }}</v-btn>
          </v-btn-toggle>
          </v-card>
        </v-row>
        <v-row justify="center" :class="$vuetify.theme.dark ? 'dark-theme-chart' : ''">
          <v-col cols="12" class="pt-0 pb-0">
            <v-card-text>
              <v-skeleton-loader class="mx-auto" v-if="chartLoading" type="card"></v-skeleton-loader>
              <highcharts v-else-if="listOfWidgets.length > 0 && chartOptionsTickets" :options="chartOptionsTickets" ref="barChart"></highcharts>
            </v-card-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
      <v-alert class="mx-3 mt-2" color="info" dark icon="mdi-cloud-alert" border="left" prominent outlined>
      {{ $t('noResults') }}
    </v-alert>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: ['chartLoading', 'listOfWidgets', 'ticketsLoading', 'widgetCount', 'chartOptionsTickets', 'index'],
  data () {
    return {
      ticketType: 0
    }
  },
  watch: {
    ticketType (val) {
      this.$eventBus.$emit('getTicketChart', { index: this.index, type: this.ticketType })
    },
    '$i18n.locale' (val) {
      this.chartOptionsTickets.yAxis.title.text = this.$t('noOfTickets')
    }
  },
  methods: {
    navigateToTickets (info) {
      window.localStorage.setItem('tickettab', 'tab-4')
      window.localStorage.setItem('CRM_ticketStatusFilter', info)
      this.$router.push('/tickets?redirectfrom=dashboard')
    }
  }
}
</script>
